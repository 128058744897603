<template>
  <ion-list v-if="resources.data.length > 0">
    <ion-list
      v-for="item in resources.data"
      :key="item.id"
      :ref="`items-${item.id}`"
    >
      <ion-card>
        <ion-item lines="none" class="ion-no-padding">
          <ion-card-header>
            <ion-card-title
              >{{ item.name }}</ion-card-title
            >
          </ion-card-header>
          <ion-button
            @click="
              confirmDelete({
                id: item.id,
              })
            "
            fill="clear"
            slot="end"
          >
            <ion-icon
              slot="icon-only"
              :ios="trashOutline"
              :md="trashSharp"
              size="small"
              style="color: #eb445a"
            ></ion-icon>
          </ion-button>
        </ion-item>
        <ion-card-content>
          {{ item.description }}
          <div class="ion-padding-top">
            Created: {{ new Date(item.created_at).toDateString() }}
          </div>
          <div v-if="item.event" class="ion-padding-top">
            Starts: {{ new Date(item.event.event_from_date).toDateString() }}
            <br>
            Ends: {{ new Date(item.event.event_to_date).toDateString() }}
          </div>
        </ion-card-content>
      </ion-card>
    </ion-list>
  </ion-list>
  <ion-list v-else>
    <ion-list-header>
      <p>No notification found</p>
    </ion-list-header>
  </ion-list>
</template>
<script>

import {
  IonList,
  IonListHeader,
  IonItem,
  IonButton,
  IonIcon,
  alertController,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
} from "@ionic/vue";

import {
  trashOutline,
  trashSharp,
} from "ionicons/icons";

export default {
  props: {
    resources: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonList,
    IonListHeader,
    IonItem,
    IonButton,
    IonIcon,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
  },
  data() {
    return {
      trashOutline,
      trashSharp,

      xhrError: {
        header: null,
        description: null,
      },
    };
  },
  methods: {
    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        console.log(error.request);
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    async confirmDelete(resource) {
      const header = `Delete notification: ${resource.name} ?`;
      const message = "This action cannot be undone.";
      const buttons = [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Okay",
          handler: () => {
            this.delete(resource.id);
          },
        },
      ];
      const alertDialog = await this.alerter(header, message, buttons);

      alertDialog.present();
    },

    async delete(resourceId) {
      try {
        const payLoad = {
          resourceId: resourceId,
        };

        await this.$store.dispatch("notification/deleteResource", payLoad);
      } catch (error) {
        this.setErrorResponse(error);
        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        alertDialog.present();
      }
    },
  },
};
</script>
