<template>
  <base-layout :title="title" :backLink="backLink" :reloadable="reloadable">
    <ion-refresher slot="fixed" @ionRefresh="refresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <ion-grid fixed>
      <ion-row>
        <ion-col size="12">
          <list-notification :resources="resources"></list-notification>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-infinite-scroll threshold="100px" @ionInfinite="loadData($event)">
      <ion-infinite-scroll-content
        loading-spinner="bubbles"
        loading-text="Loading more data..."
      >
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </base-layout>
</template>

<script>
import BaseLayout from "../../../Base/BaseLayout.vue";
import ListNotification from "../../../Components/Notification/ListNotification.vue";

import {
  IonGrid,
  IonRow,
  IonCol,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/vue";

export default {
  components: {
    BaseLayout,
    ListNotification,
    IonGrid,
    IonRow,
    IonCol,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRefresher,
    IonRefresherContent,
  },

  data() {
    return {
      title: "Notifications",
      backLink: "communication",
      reloadable: true,
    };
  },

  computed: {
    resources() {
      return this.$store.getters["notification/resources"];
    },
    shouldReload() {
      return this.$store.getters["reload"] === this.$route.name;
    },
  },

  watch: {
    shouldReload(current) {
      if (current) {
        this.refresh();
        this.$store.dispatch("clearReload");
      }
    },
  },

  methods: {
    async fetch(refresh = true) {
      await this.$store.dispatch("notification/resources", { refresh: refresh });
    },
    loadData(event) {
      this.fetch();
      event.target.complete();
    },
    async refresh(event) {
      await this.$store.dispatch("notification/resources", { refresh: true });
      if (event) {
        event.target.complete();
      }
    },
  },

  created() {
    this.fetch();
  },
};
</script>